import Layout from "../../../../src/components/layout";
import { Link } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { author, authors, conferenceLogo, institution, institutions, publication, resourceItem, teaser, title } from "../../../../src/components/publications/publication.module.css";
import * as React from 'react';
export default {
  Layout,
  Link,
  getImage,
  GatsbyImage,
  author,
  authors,
  conferenceLogo,
  institution,
  institutions,
  publication,
  resourceItem,
  teaser,
  title,
  React
};