// Copyright @2021 yucwang

import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import * as HeaderStyles from "./header.module.css"

function Header() {
  return (<header className={HeaderStyles.header}>
          <p>
            <Link to="/" className={HeaderStyles.navlink}>Home</Link>
            <Link to="/photoGallary" className={HeaderStyles.navlink}>Gallery</Link>
            <Link to="/publications" className={HeaderStyles.navlink}>Publications</Link>
            <Link to="/" className={HeaderStyles.navlink}>Projects</Link>
            <Link to="/about" className={HeaderStyles.navlink}>About</Link>
          </p>
        </header>);
}

export default Header;
