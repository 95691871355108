// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mdx-slug-tsx": () => import("./../../../src/pages/{mdx.slug}.tsx" /* webpackChunkName: "component---src-pages-mdx-slug-tsx" */),
  "component---src-pages-photo-gallary-tsx": () => import("./../../../src/pages/photoGallary.tsx" /* webpackChunkName: "component---src-pages-photo-gallary-tsx" */),
  "component---src-pages-publications-tsx": () => import("./../../../src/pages/publications.tsx" /* webpackChunkName: "component---src-pages-publications-tsx" */)
}

