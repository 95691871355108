// Copyright @yucwang 2021

import * as React from "react"
import * as MainLayoutStyles from "./layout.module.css"

import Footer from "./footer"
import Header from "./header"
import { rhythm } from "../utils/typography"

export default function MainLayout({ children }) {
  return (<body className={ MainLayoutStyles.body }>
         <Header></Header>
         <div className={ MainLayoutStyles.mainlayout }>{ children }</div>
         <Footer></Footer>
         </body>);
}
