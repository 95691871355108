// Copyright @2021 yucwang

import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import * as FooterStyles from "./footer.module.css"

function Footer() {
  return (<footer className={FooterStyles.footer}>
            <p>
              Copyright @yucwang {new Date().getFullYear()}, Built with {" "}
            </p>
            <Link href="https://www.gatsbyjs.com/" className={FooterStyles.gatsbylink}>Gatsby</Link>
          </footer>);
}

export default Footer;
